/**
 * 国际化配置
 */

const getI18nLang = () => {
  const i18ns: Record<string, Record<string, string>> = {
    'AI 时代的智能学习与社交助手，让知识触手可及': { en: 'AI smart learning and social assistant, making knowledge accessible' },
    解析结果: { en: 'Analysis Results' },
    思维导图: { en: 'Mind Map' },
    '点击彩色圆点可展开/收起节点': { en: 'Click the colored dots to expand/collapse nodes' },
    点击按钮解读此文章: { en: 'Click the button to interpret this article' },
    解读此文章: { en: 'Interpret this article' },
    内容解析中: { en: 'Content is being analyzed' },
    内容复制成功: { en: 'Content copied successfully' },
    无标题: { en: 'Untitled' },
    取消加星成功: { en: 'Star removed successfully' },
    加星成功: { en: 'Starred successfully' },
    操作失败: { en: 'Operation failed' },
    重新抓取: { en: 'Re-fetch' },
    归档: { en: 'Archive' },
    删除: { en: 'Delete' },
    归档成功: { en: 'Archived successfully' },
    重新抓取成功: { en: 'Re-fetch successful' },
    '信息来源：': { en: 'Information source:' },
    '你好，我是 Slax Reader AI 助手：': { en: 'Hello, I am the Slax Reader AI assistant:' },
    '生成问题中...': { en: 'Generating question...' },
    访问中: { en: 'Accessing' },
    [`"{tips}" 访问中...`]: { en: `"{tips}" Accessing...` },
    [`"{tips}" 搜索中...`]: { en: `"{tips}" Searching...` },
    '正在访问中...': { en: 'Accessing...' },
    '请输入你的问题（{alias} + enter 可以换行）': { en: 'Enter your question ({alias} + enter to line break)' },
    访问完成: { en: 'Access complete' },
    访问失败: { en: 'Access failed:' },
    复制内容: { en: 'Copy content' },
    你的反馈将帮助我们变得更好: { en: 'Your feedback will help us improve' },
    提交: { en: 'Submit' },
    'Slax Reader': { en: 'Slax Reader' },
    '创建你的账号，来了解一下我们吧~': { en: 'Create your account, get to know us~' },
    'Google 登录': { en: 'Google Login' },
    '支付成功!': { en: 'Payment successful!' },
    '订阅成功!': { en: 'Subscription successful!' },
    '/ Month': { en: '/ Month' },
    '选择计划:': { en: 'Select plan:' },
    $1: { en: '$1' },
    月: { en: 'Month' },
    '/ 月': { en: '/ Month' },
    '下个月起 $5.99，暂不支持银联单币种': { en: 'Starting next month $5.99, UnionPay single currency not supported' },
    支付: { en: 'Pay' },
    Stripe支付环境出错: { en: 'Stripe payment environment error' },
    订阅: { en: 'Subscribe' },
    'Pro 订阅': { en: 'Pro Subscription' },
    '订阅后，您可以体验完整的 AI 功能，包括内容解析和 AI 助手。': { en: 'After subscribing, you can experience full AI features, including content analysis and AI assistant.' },
    个人中心: { en: 'Setting' },
    退出账号: { en: 'Logout' },
    '认证中 - Slax Reader': { en: 'Authenticating - Slax Reader' },
    'AI 解析': { en: 'AI Analysis' },
    Chat: { en: 'Chat' },
    取消归档: { en: 'Unarchive' },
    已收藏: { en: 'Favorited' },
    回到顶部: { en: 'Back to top' },
    没有更多了: { en: 'No more' },
    '内容正在处理，请稍后 ···': { en: 'Content is being processed, please wait...' },
    '内容加载中 ···': { en: 'Content loading...' },
    取消归档成功: { en: 'Unarchive successful' },
    当前计划: { en: 'Current Plan' },
    加载更多: { en: 'Load more' },
    空空如也: { en: 'Empty' },
    Inbox: { en: 'Inbox' },
    Archive: { en: 'Archive' },
    Starred: { en: 'Starred' },
    下载试用: { en: 'Download trial' },
    登录: { en: 'Login' },
    常见问题: { en: 'FAQ' },
    免费和付费功能: { en: 'Free and paid features' },
    '（待上线）': { en: ' (Coming soon) ' },
    关于我们: { en: 'About us' },
    问题反馈: { en: 'Feedback' },
    简洁实用: { en: 'Simple and practical' },
    辅助阅读: { en: 'Reading assistant' },
    自动标签: { en: 'Automatic tagging' },
    全平台支持: { en: 'Convenient bookmarking' },
    缓存页和工具栏: { en: 'Cache page and toolbar' },
    'Slax Reader 是什么？': { en: 'What is Slax Reader?' },
    'Slax Reader 不仅是稍后阅读，它还是阅读者的 AI 助手、学习社区和知识库。': {
      en: "Slax Reader is not only a read-later tool, but also a reader's AI assistant, learning community, and knowledge base."
    },
    '开发团队是些什么人？': { en: 'How is our development team?' },
    '为什么想做这么个软件？': { en: 'Why create this software?' },
    免费版: { en: 'Free version' },
    无限书签: { en: 'Unlimited bookmarks' },
    '1000 次添加书签': { en: '1000 bookmarks' },
    '无限内容缓存（早期用户永久免费）': { en: 'Unlimited content cache (permanently free for early users)' },
    '1000 次内容缓存（早期用户永久免费）': { en: '1000 content cache (permanently free for early users)' },
    无限设备数量: { en: 'Unlimited number of devices' },
    无限分享: { en: 'Unlimited sharing' },
    全文搜索: { en: 'Full text search' },
    '全平台客户端（iOS、Android、Web...）': { en: 'All-platform clients (iOS, Android, Web...)' },
    无限划线评论: { en: 'Unlimited highlighting and commenting' },
    导入: { en: 'Import' },
    '付费版（抢先体验）': { en: 'Paid version (Early Access)' },
    含免费版所有功能: { en: 'Includes all features of the free version' },
    'AI 问答': { en: 'AI Q&A' },
    API: { en: 'API' },
    自动工作流: { en: 'Automated workflows' },
    '语言:': { en: 'Language:' },
    中文: { en: 'Chinese' },
    EN: { en: 'English' },
    个人信息: { en: 'Personal information' },
    订阅偏好: { en: 'Subscription preferences' },
    我的订阅: { en: 'My subscriptions' },
    第三方绑定: { en: 'Third-party binding' },
    Telegram: { en: 'Telegram' },
    帮助与支持: { en: 'Help and support' },
    'Telegram Channel': { en: 'Telegram Channel' },
    问题生成中: { en: 'Question generating' },
    生成完成: { en: 'Generation complete' },
    搜索完成: { en: 'Search complete' },
    '请描述你遇到的问题......': { en: '"Please describe the problem you are experiencing..."' },
    备份内容: { en: 'Backup Content' },
    '收藏的链接，内容会备份在你的账户里，让好内容不丢失': { en: 'Collect links, content backed up in your account to ensure good content is not lost' },
    '排版清晰大方，阅读体验舒适，操作方便自然': { en: 'Clear and generous layout, comfortable reading experience, easy and natural operation' },
    '长文章阅读的好帮手，通过 AI 协助理解，支持输出内容概要和思维导图': {
      en: 'A great helper for reading long articles, with AI assistance for understanding, supports outputting content summaries and mind maps'
    },
    '通过 AI 协助提出问题，并通过一步步的追问，深入理解文章，真正掌握知识': {
      en: 'AI-assisted questioning and step-by-step inquiry deepen understanding of articles and truly grasp knowledge'
    },
    '无需用户手动干预，自动生成可用的标签与分类，内容整理更简单': {
      en: 'Automatically generates usable tags and categories without user intervention, simplifying content organization'
    },
    '通过浏览器扩展、iOS 与安卓 App、Telegram 插件等方式收藏内容': { en: 'Collect content via browser extensions, iOS and Android apps, Telegram plugins, etc.' },
    '怎样找你们反馈问题？': { en: 'How can I provide feedback on issues?' },
    '你们的产品是免费的吗？': { en: 'Is your product free?' },
    [`可以发邮件 hi{'@'}slax.com，也可以通过 Twitter {'@'}SlaxHQ 找到我们。`]: {
      zh: `可以发邮件 hi{'@'}slax.com，也可以通过 Twitter {'@'}SlaxHQ 找到我们。`,
      en: `You can email us at hi{'@'}slax.com or find us on Twitter {'@'}SlaxHQ.`
    },
    '在 Slax Reader 打开收藏的链接，适合在电脑、平板与手机阅读，并且具备恰到好处的 AI 工具辅助学习': {
      en: 'Open collected links in Slax Reader, suitable for reading on computers, tablets, and phones, with just the right AI tools to assist learning'
    },
    '将长且复杂的文本结构化呈现，并可点击跳转到对应段落': {
      en: 'Presents long and complex texts in a structured format, with clickable links to jump to corresponding paragraphs'
    },
    '她是循循善诱的提问者，是遍读百科全书的博学之士，也是充满个性的挑战者': { en: 'She is a gentle inquirer, a well-read scholar, and a challenger full of personality' },
    'Slax Reader 和其他稍后阅读产品有什么不一样？': { en: 'What makes Slax Reader different from other read-later products?' },
    '我们希望用尽可能低的价格提供超值的服务，因此，你可以免费使用的功能包括了无限书签、无限内容缓存（早期用户永久免费）、无限设备数量、无限划线评论（待上线）、导入书签（待上线）、分享书签、全文搜索、全平台客户端。\n\n同时，因为 AI 的算力要求和企业健康经营需要，我们也有付费的专业版本，如果你需要使用 AI 解析、AI 问答、自动打标签等功能时，需要按月订阅。':
      {
        en: 'We aim to provide exceptional services at the lowest possible price, hence, you can use features for free including unlimited bookmarks, unlimited content caching (free permanently for early users), unlimited devices, unlimited highlighted comments (coming soon), bookmark imports (coming soon), sharing bookmarks, full-text search, and clients across all platforms.\n\nHowever, due to the computational demands of AI and the need for sustainable business operations, we also offer a paid professional version. If you need to use features such as AI analysis, AI Q&A, and automatic tagging, a monthly subscription is required.'
      },
    'Slax 是一家 2023 年在新加坡成立的软件工作室。产品品牌 Slax，寓意是 Simple and Relax，Slogan 是 Simple tools, relax life。\n\n我们的团队的小伙伴们年轻、富有激情和创造力、热衷技术探索、追求，期待能提供不一样的价值。':
      {
        en: 'Slax is a software studio established in Singapore in 2023. The product brand Slax implies "Simple and Relax," with the slogan "Simple tools, relax life."\n\nOur team members are young, passionate, creative, and keen on exploring technology, striving to deliver unique value.'
      },
    '我们都热爱阅读、持续学习、付费使用过很多年不同的 Read it later 产品，但始终没有一款产品符合我们的想象。\n\nAI 已经展现出在辅助学习方面的潜力，这或许是能有突破的一个变量。所以我们打算试试，长期（以十年为单位）迭代这款产品。':
      {
        en: 'We all love reading, continuous learning, and have paid to use various Read it later products over the years, but none have ever fully met our expectations.\n\nAI has shown potential in assisting learning, which may be a variable that could lead to breakthroughs. Therefore, we plan to experiment and iterate this product over the long term (in terms of decades).'
      },
    '当前只是最初的预览版本，我们的不一样是：\n\n- AI 优先：更多地使用 AI 技术辅助学习\n\n- 内容备份：互联网上的链接内容经常丢失，在我们这里，链接一次收藏，长期备份\n\n未来我们会有更多差异化的能力实现，比如：\n\n- 多智能体讨论，深化理解\n\n- 建立独特的学习者社区，大家在这里可以讨论交流\n\n- 内容广场：打破算法推荐带来的内容同质化问题\n\n- 内容订阅：你关注的人、内容、标签都将可订阅':
      {
        en: 'This is currently just an initial preview version, and our differences include:\n\n- AI Priority: Increased use of AI technology to assist learning\n\n- Content Backup: Internet link content often gets lost; here, a link once saved is backed up long-term\n\nIn the future, we will implement more differentiated capabilities, such as:\n\n- Multi-agent discussions to deepen understanding\n\n- Establishing a unique learning community where everyone can discuss and exchange ideas\n\n- Content Square: Breaking the homogenization problem brought by algorithmic recommendations\n\n- Content Subscription: Subscribe to people, content, and tags you are interested in'
      },
    '仅需 1 美元开始 7 天试用期': {
      en: 'Start 7-day trial for only $1'
    },
    '「': {
      en: ''
    },
    '」': {
      en: ''
    }
  }

  const result: Record<string, Record<string, string>> = {}
  const keys = Object.keys(i18ns)
  keys.forEach(key => {
    const item = i18ns[key]
    Object.keys(item).forEach(lang => {
      if (!result[lang]) {
        result[lang] = {}
      }

      result[lang][key] = item[lang]
    })

    if (!result['zh']) {
      result['zh'] = {}
    }

    if (!result['zh'][key]) {
      result['zh'][key] = key
    }
  })

  return result
}

export default defineI18nConfig(() => ({
  legacy: false,
  strategy: 'no_prefix',
  messages: getI18nLang()
}))
